import React from 'react';
import Card from '../../components/Card';

const ExternalPostsSummary = ({ projects }) => {
    if (!projects.length) return null;
  return (
    <div class="p-10 grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 gap-5">
      {projects.map((project) => (
        <Card
          key={project.title}
          title={project.title}
          summary={project.summary}
          link={project.link}
        />
      ))}
    </div>
    
  );
};

export default ExternalPostsSummary;
